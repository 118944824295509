a:hover {
  transform: scale(1.05); /* Agrandit légèrement tous les liens au survol */
  transition: transform 0.3s ease; /* Ajoute une transition d'échelle pour un effet doux */
}

.App {
  text-align: center;
  height: 100vh;
}
.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.barreRecherche{
  display: flex;
  justify-content: center;
    
}


.App-logo {
  height: 20vmin;
  fill: antiquewhite;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
  transform-style: preserve-3d; /* Ajout pour la transformation 3D */
  transform: rotateY(45deg); /* Ajout pour la transformation 3D */
  stroke-width: 2rem; /* Ajout pour l'épaisseur de contour */

}


.App-header {
  height: max-content;
  width: 500px;
  display: flex;
  align-self: center;
  color: black;
  transition: all 0.3s ease; 
}

@media only screen and (max-width: 600px) {
  .App-header {
    width: 100%;
  }
}


.App-link {
  color: #F7931A;
}

@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

