.footer{
	position: fixed;
	display: flex;
	width: 100%;
	height: 100px;
	justify-content: center;
	bottom: 15px;
		&-link{
		display: flex;
		color: orange;
		align-items: center;
		
	}
}
