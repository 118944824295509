.introduction{
	display: flex;
	flex-direction: column;
	justify-content: center;
	list-style-type: none;
	height: 100%;
	width: 100%;
	text-align: left;
	margin: 10px;
	margin-top: 20px;
	
	
 }
 .custom-accordion {
	background-color: transparent;
	box-shadow: none;
	transition: background-color 0.3s;

	& .accordion-summary {
		background-color: #f1f1f1;
		transition: background-color 1s;

		&.Mui-expanded {
			background-color: #7F51FE;
		}
	}

	&.expanded {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		background-color: white;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		transition: width 0.5s, height 0.5s, top 0.5s, left 0.5s;
	}

	& .accordion-details {
		background-color: white;
		position: relative;
		z-index: 1;
		padding: 16px;
		border: 1px solid #ddd;
		border-top: none;
		transition: background-color 0.3s;
		max-height: 300px;
		overflow-x: hidden;
		overflow-y: auto; /* Utilisez le défilement vertical en cas de dépassement du contenu */
		white-space: normal; /* Permet au texte de s'afficher correctement sans être coupé */
	}
}